<template>
  <div class="toggle-switch">
    <input
      type="checkbox"
      :id="id"
      :checked="modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
      :disabled="!editing"
    />
    <label :for="id" class="toggle-label"></label>
  </div>
</template>

<script>
export default {
  name: "SwitchBox",
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    editing: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.toggle-switch {
  position: relative;
  width: 50px;
  display: inline-block;
  text-align: left;
}

.toggle-switch input {
  display: none;
}

.toggle-switch .toggle-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #ddd;
  border-radius: 20px;
  margin: 0;
}

.toggle-switch .toggle-label:before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background: #ddd;
  border-radius: 50%;
  transition: 0.3s;
}

.toggle-switch input:checked + .toggle-label {
  background: #2cbeff;
  border-color: #2cbeff;
}

.toggle-switch input:checked + .toggle-label:before {
  transform: translateX(26px);
  background: #fff;
}

.toggle-switch input:disabled + .toggle-label {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
