<template>
  <div class="flex-grow-1 rel" :key="selected + '-' + social.socialId">
    <div
      class="panel-header tab-header pl24 pr24 infotronik_social_header_interno"
    >
      <!-- <div class="mini-logo "></div> -->
      <ul
        class="flex-container layout-list nopadding nomargin flex-end-align flex-end-justify h100"
      >
        <li class="layout-list-item">
          <a
            href="#"
            :class="
              selected === 'social'
                ? 'layout-list-item-btn pl16 pr24 selected infotronik_social_tab_selsocial'
                : 'layout-list-item-btn pl16 pr24 infotronik_social_tab_notselsocial'
            "
            @click.prevent="onTabSelected('social')"
          >
            <!--<div class="layout-list-icon admin-icon"></div>-->
            <span class="pl8"><i class="lni lni-stats-up"></i></span>
          </a>
        </li>
        <li v-if="isAdmin()" class="layout-list-item">
          <a
            href="#"
            :class="
              selected === 'campagne'
                ? 'layout-list-item-btn pl16 pr24 selected infotronik_social_tab_selcampagne'
                : 'layout-list-item-btn pl16 pr24 infotronik_social_tab_notselcampagne'
            "
            @click.prevent="onTabSelected('campagne')"
          >
            <!--<div class="layout-list-icon admin-icon"></div>-->
            <span class="pl8"><i class="lni lni-calendar"></i></span>
          </a>
        </li>

        <li v-if="isAdmin()" class="layout-list-item">
          <a
            href="#"
            :class="
              selected === 'aggiorna'
                ? 'layout-list-item-btn pl16 pr24 selected infotronik_social_pulsanteaggiorna infotronik_social_pulsanteaggiorna_disable'
                : 'layout-list-item-btn pl16 pr24 infotronik_social_pulsanteaggiorna infotronik_social_pulsanteaggiorna_disable'
            "
            @click.prevent="aggInsights()"
            id="aggiorna"
          >
            <span class="pl8"><i class="lni lni-reload"></i></span>
          </a>
        </li>
      </ul>
    </div>
    <PublisherSocialCard
      v-if="social && showPublisherCard && selected === 'social'"
      :key="'publisher-' + social.socialId"
      :selSocial="social"
    ></PublisherSocialCard>
    <SocialCard
      v-else-if="social && selected === 'social'"
      :key="social.socialId"
      :selSocial="social"
    ></SocialCard>
    <SocialCampaignsCard
      v-else-if="social && selected === 'campagne'"
      :key="'campaign-' + social.socialId"
      :selSocial="social"
    ></SocialCampaignsCard>
  </div>
</template>
<script>
import { ref, provide } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import SocialCard from "@/components/social/cards/SocialCard";
import PublisherSocialCard from "@/components/social/cards/PublisherSocialCard";
import SocialCampaignsCard from "@/components/social/cards/SocialCampaignsCard";
import { useCapabilities } from "@/utils/Capabilities";
export default {
  props: {
    selectedTab: {
      type: String,
      default: "social",
    },
    social: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    SocialCard,
    PublisherSocialCard,
    SocialCampaignsCard,
  },

  methods: {
    aggInsights() {
      this.emitter.emit("aggirnadatiInsights", "1");
    },
  },

  setup(props) {
    provide("currentSocial", props.social);
    const selected = ref(props.selectedTab);
    const router = useRouter();
    const route = useRoute();
    const { isAdmin, isAdvertiser, isPublisher, isConcessionaire } =
      useCapabilities();
    const showPublisherCard = isPublisher() || isConcessionaire();

    if (route.query.tab === undefined) {
      selected.value = "social";
    } else {
      selected.value = route.query.tab;
    }
    const onTabSelected = (tab) => {
      selected.value = tab;
      router.push({ path: route.path, query: { tab: tab } });
    };

    onBeforeRouteUpdate(async (to, from) => {
      if (to.params.id === from.params.id && to.query !== from.query) {
        selected.value = to.query.tab || "social";
      }
    });
    return {
      selected,
      onTabSelected,
      //meet2,
      isAdmin,
      isAdvertiser,
      isPublisher,
      isConcessionaire,
      showPublisherCard,
    };
  },
};
</script>
<style>
.panel-header {
  background-color: white;
}
</style>
