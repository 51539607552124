<template>
     <li :class="className + ' accordion-social-element mb16 p16'">
         <div class="flex-container flex-space-between-justyfy w100 flex-center-align">
                <h3 class="bebas pr16 nomargin">{{socialCampaign.campaign.title}}</h3>
                <h4>
                    costo {{formatNumber(socialCampaign.cost)}} €
                </h4> 
                  
                <InflooButton v-if="!isOpen" 
                    @click.prevent="onOpen"
                    :className="'button arrow pl8 pr8'"
                >
                     <i class="lni lni-chevron-down"></i>
                </InflooButton>
                <InflooButton v-else-if="isOpen" 
                    @click.prevent="onClose" 
                    :className="'button arrow pl8 pr8'"
                >
                     <i class="lni lni-chevron-up"></i>
                </InflooButton> 
            </div>
            <LayoutPostAndAdverts
                v-if="isOpen"
                :hasPosts="postData.posts && postData.posts.length > 0"
                :hasAdverts="postData.advertisements && postData.advertisements.length > 0"
            >
                <template v-slot:posts>
                    <ul   class="layout-list nomargin">
                        
                        <FacebookPostAnalytics 
                            v-for="post in postData.posts" 
                            :key=post.postId 
                            :post="post"
                        >
                            {{post.message}}
                        </FacebookPostAnalytics>
                    </ul>
                </template>
                <template v-slot:adverts>
                    <ul  class="layout-list nomargin striped">
                        <FacebookAdvertAnalyticsElement 
                            v-for="advert in postData.advertisements" 
                            :key=advert.facebookAdId 
                            :advert="advert"
                            class="layout-list-item"
                            :editing="true"
                            :open="true"
                        >
                        </FacebookAdvertAnalyticsElement>
                    </ul>
                </template>
            </LayoutPostAndAdverts>
     </li>    
</template>
<script>
import {ref, reactive, provide} from 'vue'
import {formatNumber} from '@/utils/NumberFunctions'
import InflooButton from '@/components/buttons/InflooButton'
import FacebookAdvertAnalyticsElement from '@/components/campaigns/analytics/facebook/FacebookAdvertAnalyticsElement'
import FacebookPostAnalytics from '@/components/campaigns/analytics/facebook/FacebookPostAnalytics'
import LayoutPostAndAdverts from '@/components/campaigns/analytics/facebook/LayoutPostAndAdverts'
import {apiSocialFunction} from '@/api/socials'
export default {
    props:{
        socialCampaign:{
            type:Object,
            default:()=>{}
        },
        className:{
            type:String,
            default:'layout-list-item'
        },
        socialId:{
            type:String,
            default:null
        }
    },
    components:{
        InflooButton,
        LayoutPostAndAdverts,
        FacebookPostAnalytics,
        FacebookAdvertAnalyticsElement
    },
    setup(props){
        const isOpen = ref(false)
        const postsLoaded = ref(false)
        const postData = reactive({
            posts:null,
            advertisements:null
        })
        const onOpen = async () => {
            isOpen.value = true
            if(!postsLoaded.value){
                const {data} = await apiSocialFunction.getSocialCampaignPosts(props.socialId, props.socialCampaign.campaignId)
                postsLoaded.value = true
                postData.posts = data.posts                
                postData.advertisements = data.advertisements                
            }
            
        }
        const onClose = () => {
            isOpen.value = false
        }

        provide('campaign', props.socialCampaign.campaign)
        return {
            formatNumber,
            isOpen,
            onOpen,
            onClose,
            postData
        }
    }
}
</script>
<style lang="postcss">
.accordion-social-element{
        background-color: white;
    }
</style>