<template>
     <div
        v-if="insight" 
        class="flex-container flex-col lh24 pt16">
            <div class="flex-container flex-col mb16">
                <div class="flex-container no-grow">
                    <text-input
                        :label="'Click sul link'"
                        :value="formatNumber(insight.linkClicks) || 0"
                        :editing="false"
                        :name="'clicksLink'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'Altri Click'"
                        :value="formatNumber(insight.otherClicks) || 0"
                        :editing="false"
                        :name="'otherClicks'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'Visualizzazioni foto'"
                        :value="formatNumber(insight.photoView )|| 0"
                        :editing="false"
                        :name="'photoView'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                </div>
                <div class="flex-container no-grow">
                    <text-input
                        :label="'reaction'"
                        :value="formatNumber(insight.reactions) || 0"
                        :editing="false"
                        :name="'reactions'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'commenti'"
                        :value="formatNumber(insight.comments) || 0"
                        :editing="false"
                        :name="'comments'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'condivisioni'"
                        :value="formatNumber(insight.shared) || 0"
                        :editing="false"
                        :name="'shared'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                </div>
                <div class="flex-container  no-grow">
                    <text-input
                        :label="'impressions'"
                        :value="formatNumber(insight.impressions) || 0"
                        :editing="false"
                        :name="'impressions'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'impression a pagamento'"
                        :value="formatNumber(insight.impressionsSponsored) || 0"
                        :editing="false"
                        :name="'impressionsSponsored'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'impression organiche'"
                        :value="formatNumber(insight.impressionsOrganic) || 0"
                        :editing="false"
                        :name="'impressionsOrganic'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                </div>
                <div class="flex-container flex-start-align">
                    <text-input
                        :label="'copertura'"
                        :value="formatNumber(insight.totalCoverage) || 0"
                        :editing="false"
                        :name="'totalCoverage'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'copertura a pagamento'"
                        :value="formatNumber(insight.sponsoredCoverage) || 0"
                        :editing="false"
                        :name="'sponsoredCoverage'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'copertura organiche'"
                        :value="formatNumber(insight.organicCoverage) || 0"
                        :editing="false"
                        :name="'organicCoverage'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    
                </div>
            </div>
            <hr>
            <div 
                class="flex-container flex-start-align mb16"
            >
                <div class="flex-container flex-col flex-start-align">
                    <text-input
                        :label="'3 secondi'"
                        :value="formatNumber(insight.videoViews3S) || 0"
                        :editing="false"
                        :name="'videoViews3S'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'3 secondi totale uniche'"
                        :value="formatNumber(insight.videoViews3SUnique) || 0"
                        :editing="false"
                        :name="'videoViews3SUnique'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'3 secondi totale organica'"
                        :value="formatNumber(insight.videoViews3SOrganic) || 0"
                        :editing="false"
                        :name="'videoViews3SOrganic'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input> 
                    <text-input
                        :label="'3 secondi totale a pagamento'"
                        :value="formatNumber(insight.videoViews3SSponsored)|| 0" 
                        :editing="false"
                        :name="'videoViews3SSponsored'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    
                </div>
                <div class="flex-container flex-col flex-start-align">
                    
                    <text-input
                        :label="'10 secondi'"
                        :value="formatNumber(insight.videoViews10S) || 0"
                        :editing="false"
                        :name="'video-view-10-secondi'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'10 secondi totale uniche'"
                        :value="formatNumber(insight.videoViews10SUnique) || 0"
                        :editing="false"
                        :name="'video-view-10-secondi-totale-uniche'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'10 secondi totale organica'"
                        :value="formatNumber(insight.videoViews10SOrganic) || 0"
                        :editing="false"
                        :name="'videoViews10SOrganic'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input> 
                    <text-input
                        :label="'10 secondi totale a pagamento'"
                        :value="formatNumber(insight.videoViews10SSponsored) || 0"
                        :editing="false"
                        :name="'videoViews10SSponsored'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                   
                </div>
                <div class="flex-container flex-col flex-start-align">
                    <text-input
                        :label="'15 secondi'"
                        :value="formatNumber(insight.videoViews15S) || 0"
                        :editing="false"
                        :name="'videoViews15S'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                </div>
            </div>
        </div> 
</template>
<script>
//import {inject} from 'vue'
import TextInput from '@/components/formelements/TextInput'
import {formatNumber} from '@/utils/NumberFunctions'
export default {
    props:{
        insight:{
            type:Object,
            default:()=>{}
        }
    },
    components:{
        TextInput
    },
    setup(){
        //const campaign = inject('campaign')
        
        
        return{
           // campaign,
            formatNumber,
            
        }
    }
}
</script>
<style lang="postcss">
    .analytics-totals{
        
        & label h3 {
            font-size: .9em;
        }
    }
</style>