<template>
  <div class="layout-list-header">
    <span class="layout-list-header-title bebas f20 pl16">Socials</span>
    <a href="#" class="btn-new" @click.prevent="createVisible = true">
      +
    </a>
  </div>

  <transition name="downup">
    <div v-if="createVisible" class="create-user-panel" ref="panel">
      <div class="layout-list-header">
        <span class="layout-list-header-title bebas f20 pl16">Aggiungi</span>
        <a class="btn-new btn-chiudi" @click.prevent="createVisible = false"
          >x</a
        >
      </div>
      <div class="layout-list-content">
        <ul class="layout-list nopadding nomargin">
          <li class="layout-list-item">
            <a
              href="#"
              class="layout-list-item-btn pl16 pr24"
              @click.prevent="() => createSocial('Facebook')"
              >Pagina Facebook</a
            >
          </li>
          <li class="layout-list-item">
            <a href="#" class="layout-list-item-btn pl16 pr24" @click.prevent>
              Instagram
            </a>
          </li>
        </ul>
      </div>
    </div>
  </transition>
  <CreateFacebookPanel
    v-if="creatorOpen"
    @close="creatorOpen = false"
  ></CreateFacebookPanel>

  <div class="layout-list-header pr16">
    <div class="layout-list-content">
      <select
        v-model="selected"
        @change.prevent="socialFilter(selected)"
        class="noborder nopadding pl8 infotronik_social_filterlist"
      >
        <option value="Tutti" @change.prevent="() => socialFilter('Tutti')"
          >Tutti</option
        >
        <option
          value="Facebook"
          @change.prevent="() => socialFilter('Facebook')"
          >Facebook</option
        >
        <option
          value="Instagram"
          @change.prevent="() => socialFilter('Facebook')"
          >Instagram</option
        >
      </select>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import CreateFacebookPanel from "@/components/social/facebook/CreateFacebookPanel";

export default {
  components: {
    CreateFacebookPanel,
  },
  setup() {
    const store = useStore();

    const createVisible = ref(false);

    const creatorOpen = ref(false);

    const showCreate = () => {
      createVisible.value = true;
    };

    const hideCreate = () => {
      createVisible.value = false;
      creatorOpen.value = false;
    };

    const createSocial = async (/* social */) => {
      createVisible.value = false;
      creatorOpen.value = true;
    };
    const iconClassName = (s) => {
      let cls = "layout-list-icon ";
      switch (s) {
        case "facebook":
          cls += "adv-icon";
          break;
        case "instagram":
          cls += "adv-icon";
          break;
      }
      return cls;
    };

    const selected = ref("Tutti");

    const socialFilter = (social) => {
      selected.value = social;
      const filter = {
        type: "discriminator",
        value: social,
      };
      store.dispatch("social/filter", filter);
    };

    return {
      selected,
      showCreate,
      hideCreate,
      createVisible,
      createSocial,
      iconClassName,
      socialFilter,
      creatorOpen,
    };
  },
};
</script>
