<template>
  <teleport to="#overlay">
    <div class="overlay w100 h100 abs">
      <div class="overlay-panel rel">
        <form
          class="flex-container flex-col h100"
          id="panel"
          @submit.prevent="onSubmit"
        >
          <div class="panel-header p24">
            <div class="mini-logo"></div>
            <h2 class="panel-title nomargin bebas">Aggiungi Pagina Facebook</h2>
          </div>
          <div class="flex-container pl24 pr24 pb24">
            <AjaxSearchSelect
              label="Associa a Publisher"
              :feedUrl="ApiEndPoints.USER_SCOPE"
              :params="{
                discriminator: USER_SCOPE_PUBLISHER_DISCRIMINATOR,
              }"
              @select="onPublisherChange"
              className="flex-container flex-col"
            >
            </AjaxSearchSelect>
          </div>
          <div class="flex-container flex-center-justify pb24">
            <!--FacebookWrapper
              :fbUser="fbUser"
              :message="'login con facebook'"
              @fb-login="onFacebookLog"
              @fb-no-login="onFacebookNoLog"
            >
              <FacebookPages
                v-if="fbUser"
                :key="fbUser.id"
                :authResponse="fbUser"
                @list-select="onPageSelect"
              >
              </FacebookPages>
            </FacebookWrapper-->
            <FacebookPages @list-select="onPageSelect"> </FacebookPages>
          </div>

          <div class="flex-container w100 abs panel-footer">
            <div class="flex-container">
              <input
                type="reset"
                value="annulla"
                class="w100 noborder nopadding bebas"
                @click.prevent="emit('close')"
              />
              <input
                type="submit"
                value="invia"
                class="w100 noborder nopadding bebas"
                :disabled="preventNext"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </teleport>
</template>
<script>
import { reactive, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
//import SelectInput from '@/components/formelements/SelectInput'
import FacebookPages from "@/components/social/facebook/FacebookPages";
//import FacebookWrapper from "@/components/social/facebook/FacebookWrapper";
import AjaxSearchSelect from "@/components/formelements/AjaxSearchSelect";
import { ApiEndPoints } from "@/api/Endpoints";
import { USER_SCOPE_PUBLISHER_DISCRIMINATOR } from "@/utils/RolesAndPolicies";
export default {
  emits: ["close"],
  components: {
    //FacebookWrapper,
    //SelectInput,
    FacebookPages,
    AjaxSearchSelect,
  },
  setup(_, { emit }) {
    const store = useStore();
    const router = useRouter();

    const data = reactive({
      socialId: false,
      accessToken: null,
      publisherId: false,
      facebookUserId: null,
    });
    const fbUser = ref(null);

    const publishers = computed(() => {
      return store.getters["userscope/publishers"];
    });

    const onPageSelect = (page) => {
      data.socialId = page.id;
      data.accessToken = page.access_token;
    };

    const onSubmit = async () => {
      try {
        console.log("submitting", data);
        const response = await store.dispatch("social/create", {
          data: data,
        });
        emit("close");
        await store.dispatch("messages/message", {
          title: "Pagina Facebook Aggiunta",
          message: response.name,
        });
        router.push("/social/" + response.socialId);
      } catch (error) {
        await store.dispatch("messages/message", {
          title: "errore nella creazione della pagina Facebook",
          message: error.message,
        });
      }
    };

    const preventNext = computed(() => {
      return !data.socialId || !data.accessToken || !data.publisherId;
    });

    const onPublisherChange = (publisher) => {
      //data.publisherId = publishers.value.find(p => p.name === e.target.value).id
      data.publisherId = publisher.id;
    };
    const onFacebookLog = async (response) => {
      fbUser.value = response;
      //data.accessToken= response.accessToken
      data.facebookUserId = response.userID;
    };
    const onFacebookNoLog = () => {
      fbUser.value = null;
    };

    return {
      emit,
      onSubmit,
      data,
      publishers,
      preventNext,
      onPublisherChange,
      fbUser,
      onFacebookNoLog,
      onFacebookLog,
      onPageSelect,
      ApiEndPoints,
      USER_SCOPE_PUBLISHER_DISCRIMINATOR,
    };
  },
};
</script>
