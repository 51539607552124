<template>
  <li class="layout-list-item">
    <router-link
      v-if="mode === 'navigation'"
      :to="
        route.query.tab
          ? '/social/' + social.socialId + '?tab=' + route.query.tab
          : '/social/' + social.socialId
      "
      :class="
        selected
          ? 'layout-list-item-btn flex-container p8 pl16 selected'
          : 'layout-list-item-btn flex-container p8 pl16 '
      "
      @click="openSocial(social.name, social.socialId)"
    >
      <div class="layout-list-item-title nomargin">{{ social.name }}</div>
    </router-link>
    <a
      v-else-if="mode === 'trigger'"
      href="#"
      :class="
        isSelected
          ? 'layout-list-item-btn flex-container p8 pl16 selected'
          : 'layout-list-item-btn flex-container p8 pl16 '
      "
      @click.prevent="
        isSelected = true;
        this.$emit('list-select', social);
      "
    >
      <div class="layout-list-item-title nomargin">{{ social.name }}</div>
    </a>
  </li>
</template>
<script>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
export default {
  methods: {
    openSocial() {
      /////emetto nascondendo o meno le colonne////
      this.emitter.emit("Socialmostralista", false);
      this.emitter.emit("SocialDettaglisocial", false);
      this.emitter.emit("SocialDettaglisocial2", true);

      ////////////breadcrumb//////////////
      //creo il breadcrumb per l'utente///
      this.routes = ref([
        //{ name: 'Home', path: '/' },
        { name: "Social", path: "/socials" },
        //{ name: usern, path: '/user/'+userid }
      ]);
      ////////////breadcrumb//////////////
      //creo il breadcrumb per l'utente///
      //userid;
      this.emitter.emit("breadcrumb", this.routes);

      /////////////////////////////////////
    },
  },

  emits: ["list-select"],
  props: {
    social: {
      type: Object,
      default: () => {},
    },
    selectedId: {
      type: [Number, String],
      default: 0,
    },
    mode: {
      type: String,
      default: "navigation",
    },
  },
  setup(props) {
    const routes = ref([
      //{ name: 'Home', path: '/' },
      { name: "Social", path: "/socials" },
      //{ name: usern, path: '/user/'+userid }
    ]);

    const selected = computed(() => {
      return props.selectedId === parseInt(props.social.socialId);
    });

    const isSelected = ref(false);
    const route = useRoute();
    return {
      selected,
      isSelected,
      route,
      routes,
    };
  },
};
</script>
