<template>
  <form
    class="card-form edit-user flex-container flex-col"
    @submit.prevent
    ref="form"
  >
    <div class="layout-content-header pt24 pl24 pr24 no-grow">
      <!--  <div class="layout-content-header pt24 pl24 pr24 no-grow flex-container flex-space-between-justyfy" >   -->
      <h1 class="nomargin bebas" style="text-align: center !important">
        {{ social.name }}
      </h1>
      <!--<input 
                v-if="social.discriminator ==='Facebook'" 
                type="submit" value="Aggiorna dati" 
                class="noborder bebas p8 headerbadge" 
                @click.prevent="onReload" 
                :disabled="!editing"               
            >-->
    </div>
    <div class="content p24 h100 infotronik_social_totalcontent">
      <!-- blocco in alto a sinistra -->
      <div class="mb8 infotronik_social_macroblocco">
        <div class="infotronik_social_macroblocco_interno">
          <h2 class="nomargin bebas infotronik_social_macroblocco_titolo">
            DATI PAGINA
          </h2>
          <!-- se non è advertiser -->
          <div
            id="infotronik_social_macroblocco_datipagina"
            class="infotronik_social_macroblocco_interno_contenitore"
          >
            <!-- nome pagina -->
            <TextInput
              :label="'nome pagina'"
              :value="social.name"
              :editing="false"
              :name="'name'"
              :placeholder="social.name"
              :className="'mb8 pr8'"
              class="infotronik_social_input_spaziomassimo"
            >
            </TextInput
            ><br />
            <!-- piattaforma -->
            <SelectInput
              :label="'Piattaforma social'"
              :value="social.discriminator"
              :editing="false"
              :name="'discriminator'"
              :id="'discriminator'"
              :placeholder="'scegli il tipo di pagina social'"
              :options="['Facebook', 'Instagram']"
              :className="'mb8 pl8'"
            ></SelectInput
            ><br />
            <!-- url -->
            <TextInput
              :label="'URL'"
              :value="social.link"
              :editing="false"
              :name="'link'"
              :placeholder="'inserisci url pagina social'"
              :className="'mb8 pr8'"
              class="infotronik_social_input_spaziomassimo"
            >
            </TextInput
            ><br />
            <!-- followers -->
            <TextInput
              :label="'Followers'"
              :value="formatNumber(social.follower)"
              :editing="false"
              :name="'follower'"
              :className="'mb8 pr8'"
            >
            </TextInput
            ><br />
            <!-- target -->
            <AjaxSearchSelect
              :config="{
                mode: 'tags',
                trackBy: 'name',
                label: 'name',
                valueProp: 'name',
              }"
              :editing="editing"
              :feedUrl="ApiEndPoints.TAGS"
              @select="onTagAdd"
              :key="'ajax-search-' + keyCounter"
              :selected="social.socialTags.map((t) => t.tag)"
              @deselect="onTagRemove"
              label="target associati alla pagina "
            >
            </AjaxSearchSelect>
          </div>
        </div>
      </div>
      <!-- blocco in alto a destra -->
      <div class="mb8 infotronik_social_macroblocco">
        <div class="infotronik_social_macroblocco_interno">
          <h2 class="nomargin bebas infotronik_social_macroblocco_titolo">
            DATI TECNICI
          </h2>
          <!-- se non è advertiser -->
          <div class="pnt infotronik_editore_slide_premium">
            <label class="ns">
              Attivo
              <SwitchBox
                v-model="social.enabled"
                :editing="editing"
                :id="'editor_enabled'"
              />
            </label>
          </div>
          <div class="pnt infotronik_editore_slide_premium">
            <label class="ns">
              Premium
              <SwitchBox
                v-model="social.isPremium"
                :editing="editing"
                :id="'editor_premium'"
              />
            </label>
          </div>
          <div
            v-if="isAdmin()"
            id="infotronik_social_macroblocco_datitecnici"
            class="infotronik_social_macroblocco_interno_contenitore"
          >
            <AjaxSearchSelect
              :editing="editing"
              label="Seleziona Publisher"
              :feedUrl="ApiEndPoints.USER_SCOPE"
              :params="{
                discriminator: USER_SCOPE_PUBLISHER_DISCRIMINATOR,
              }"
              @select="onPublisherChange"
              :key="'ajax-search-publisher-' + keyCounter"
              :selected="social?.publisher"
              :className="'flex-container flex-col mb8 pl8'"
            >
            </AjaxSearchSelect>
            <!-- tipo prezzo -->
            <PriceTypes
              :editing="editing"
              :priceType="social.priceType"
              :price="social.price"
              :organicCoveragePrice="social.organicCoveragePrice"
              :sponsoredCoveragePrice="social.sponsoredCoveragePrice"
              :key="'prices-' + keyCounter"
            >
            </PriceTypes>
          </div>
          <!-- fine se non è advertiser -->
          <!-- se è advertiser -->
          <div
            v-if="isAdvertiser()"
            id="infotronik_social_macroblocco_datitecnici"
            class="infotronik_social_macroblocco_interno_contenitore"
          >
            <AjaxSearchSelect
              :editing="editing"
              label="Seleziona Publisher"
              :feedUrl="ApiEndPoints.USER_SCOPE"
              :params="{
                discriminator: USER_SCOPE_PUBLISHER_DISCRIMINATOR,
              }"
              @select="onPublisherChange"
              :key="'ajax-search-publisher-' + keyCounter"
              :selected="social?.publisher"
              :className="'flex-container flex-col mb8 pl8'"
            >
            </AjaxSearchSelect>
          </div>
          <!-- fine se è advertiser -->
        </div>
      </div>
    </div>
    <div class="infotronik_social_separator">
      <div class="infotronik_social_separator_left"></div>
      <div class="infotronik_social_separator_right"></div>
    </div>
    <div class="content p24 h100 infotronik_social_chartcontent">
      <SocialInsight
        :caller="caller_id"
        :socialPageId="social.socialId"
        :editor="social"
      >
      </SocialInsight>
    </div>
    <div
      v-show="this.social"
      id="temp_blocco_instagram"
      class="infotronik_socialfacebook_instagram"
    >
      <InstagramCard
        :editor="this.social"
        @data-saved="saveIgData($event)"
        class="infotronik_social_instagramcard"
      />
    </div>
    <!-- @edit-enable="editing = true"  -->
    <form-footer
      v-if="isAdmin()"
      @edit-enable="abilitaediting"
      @edit-disable="disableEdit"
      @submit-form="savePressed"
      @delete-press="onDeleteClick"
      :editing="editing"
      :className="'layout-content-footer abs infotronik_social_footer'"
    ></form-footer>
  </form>
  <ConfirmPanel
    v-if="confirmVisible"
    :title="'Elimina ' + social.name"
    :message="'sei sicuro di voler eliminare ' + social.name + '?'"
    @allow-confirm="onDeleteConfirm"
    @deny-confirm="confirmVisible = false"
  >
  </ConfirmPanel>
  <FacebookResetToken
    v-if="resettingToken"
    @close="resettingToken = false"
    :socialId="social.socialId"
  ></FacebookResetToken>
</template>
<script>
import { ref, computed, reactive /* onMounted */ } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ApiEndPoints } from "@/api/Endpoints";
import { USER_SCOPE_PUBLISHER_DISCRIMINATOR } from "@/utils/RolesAndPolicies";
import AjaxSearchSelect from "@/components/formelements/AjaxSearchSelect";
import TextInput from "@/components/formelements/TextInput";
import { formatNumber } from "@/utils/NumberFunctions";
import SelectInput from "@/components/formelements/SelectInput";
import FormFooter from "@/components/formelements/FormFooter";
import PriceTypes from "@/components/formelements/PriceTypes";
import FacebookResetToken from "@/components/FacebookResetToken";
import ConfirmPanel from "@/components/ConfirmPanel";
import { arraysContainSame } from "@/utils/ArrayFunctions";
import { apiSocialFunction } from "@/api/socials";
import SocialInsight from "@/components/social/SocialInsight";
import InstagramCard from "@/components/social/cards/InstagramCard.vue";
import SwitchBox from "@/components/SwitchBox.vue";
import { useCapabilities } from "@/utils/Capabilities";

export default {
  props: {
    selSocial: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    TextInput,
    SelectInput,
    AjaxSearchSelect,
    ConfirmPanel,
    FormFooter,
    PriceTypes,
    FacebookResetToken,
    SocialInsight,
    InstagramCard,
    SwitchBox,
  },
  created() {
    this.caller_id = 1;
  },
  setup(props) {
    const keyCounter = ref(0);
    const store = useStore();
    const router = useRouter();
    const { isAdmin, isAdvertiser, isPublisher } = useCapabilities();
    const resettingToken = ref(false);
    const social = reactive(props.selSocial);

    const editing = ref(false);
    //const originalTags = props.selSocial.socialTags.map(t =>t)
    const originalTagsIds = props.selSocial.socialTags.map((t) => t.tagId);

    const publishers = computed(() => {
      return store.getters["userscope/publishers"];
    });

    const onTagAdd = (tag) => {
      const socialTag = {
        tag: tag,
        tagId: tag.id,
        socialId: social.socialId,
      };
      social.socialTags.push(socialTag);
    };
    const onTagRemove = (tag) => {
      social.socialTags = social.socialTags.filter((t) => t.tagId !== tag.id);
    };

    const onPublisherChange = (publisher) => {
      /* social.publisher = publishers.value.find(p => p.name === publisher)
            social.publisherId= publishers.value.find(p => p.name === publisher).id
             */
      social.publisher = publisher;
      social.publisherId = publisher.id;
    };

    const form = ref(null);

    const syncTags = async () => {
      try {
        await store.dispatch("social/updateSocialTags", {
          id: social.socialId,
          //tags:currentTags.value
          tags: social.socialTags.map((t) => t.tag),
        });
      } catch (error) {
        await store.dispatch("messages/message", {
          title: "errore nella modifica dei target",
          message: error.message,
        });
      }
    };

    const savePressed = async () => {
      if (
        !arraysContainSame(
          originalTagsIds,
          social.socialTags.map((t) => t.tagId)
        )
      ) {
        await syncTags();
      }

      save();
    };

    const abilitaediting = async () => {
      editing.value = true;

      document
        .getElementById("aggiorna")
        .classList.remove("infotronik_social_pulsanteaggiorna_disable");
      ///qua
    };

    const save = async () => {
      const formData = new FormData(form.value);
      const data = {};
      for (let key of formData.keys()) {
        data[key] = formData.get(key);
      }
      console.log("save");
      try {
        await store.dispatch("social/update", {
          id: social.socialId,
          data: {
            ...data,
            publisherId: social.publisherId,
            Enabled: social.enabled,
            isPremium: social.isPremium,
          },
          socialCampaigns: social.socialCampaigns,
          socialTags: social.socialTags,
        });
        await store.dispatch("messages/message", {
          title: social.name,
          message: "la pagina social è stata modificata correttamente",
        });
      } catch (error) {
        await store.dispatch("messages/message", {
          title: "errore nella modifica della pagina Social",
          message: error.message,
        });
      }
    };

    //ricarica/aggiorna i dati del social
    const onReload = async () => {
      try {
        const data = await store.dispatch("social/fetchfacebook", {
          id: social.socialId,
        });
        //social = reactive(data)

        const keys = Object.keys(data);

        keys.forEach((key) => {
          social[key] = data[key];
        });

        editing.value = false;
      } catch (error) {
        if (
          error.message === "190" ||
          error.message.startsWith("Invalid OAuth access token.")
        ) {
          resettingToken.value = true;
        } else {
          await store.dispatch("messages/message", {
            title: "error fetching data",
            message: error.message,
          });
        }
      }
    };
    const confirmVisible = ref(false);

    const onDeleteClick = async () => {
      confirmVisible.value = true;
    };
    const onDeleteConfirm = async () => {
      try {
        await store.dispatch("social/remove", {
          id: social.socialId,
          discriminator: social.discriminator,
        });

        await store.dispatch("messages/message", {
          title: "Social eliminato",
          message: "hai cancellato " + social.name,
        });
        router.push("/socials");
        window.location = "/socials";
      } catch (error) {
        await store.dispatch("messages/message", {
          title: "errore nella eliminazione",
          message: error.message,
        });
      }
    };

    const disableEdit = async () => {
      if (social.discriminator === "Facebook") {
        const singleSocial = await apiSocialFunction.showFb(
          props.selSocial.socialId
        );

        Object.keys(social).forEach((key) => {
          social[key] = singleSocial[key];
        });
        social.publisher = publishers.value.find(
          (p) => p.id === social.publisherId
        );
        social.publisherId = publishers.value.find(
          (p) => p.id === social.publisherId
        )?.id;
        keyCounter.value++;
      }
      editing.value = false;

      document
        .getElementById("aggiorna")
        .classList.add("infotronik_social_pulsanteaggiorna_disable");
      ////qui
    };

    const saveIgData = (editor) => {
      if (!editor) return;
      //this.editor.instagram_Link = editor.instagram_Link;
      //this.editor.instagram_Fanbase = editor.instagram_Fanbase;
    };

    return {
      social,
      form,
      isAdmin,
      isAdvertiser,
      isPublisher,
      save,
      savePressed,
      editing,
      resettingToken,
      publishers,
      disableEdit,
      onPublisherChange,
      onTagAdd,
      onTagRemove,
      onReload,
      onDeleteConfirm,
      onDeleteClick,
      confirmVisible,
      keyCounter,
      formatNumber,
      ApiEndPoints,
      USER_SCOPE_PUBLISHER_DISCRIMINATOR,
      SocialInsight,
      abilitaediting,
      saveIgData,
    };
  },
};
</script>
<style>
.resizable-column-container {
  overflow: scroll !important;
}
</style>
