<template>
  <div
    v-if="isLoading"
    class="flex-container flex-col scrollable-content pl24 pb24 fb-list"
  >
    <span style="width: 100%; text-align: center">Caricamento pagine...</span>
    <Spinner id="spinner"></Spinner>
  </div>
  <ul
    v-else
    class="flex-container flex-col scrollable-content pl24 pb24 fb-list"
    style="height: 50vh"
  >
    <SocialListElement
      v-for="page in fbData.data"
      :key="page.id"
      :social="page"
      mode="trigger"
      @list-select="onSelect"
      :selectedId="selected ? selected.id : ''"
    ></SocialListElement>
  </ul>
</template>
<script>
import { reactive, onMounted, ref } from "vue";
import SocialListElement from "./../SocialListElement";
import Spinner from "@/components/Spinner.vue";
import { useStore } from "vuex";

export default {
  emits: ["list-select"],
  props: {
    authResponse: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    SocialListElement,
    Spinner,
  },
  setup(props, { emit }) {
    const store = useStore();
    const isLoading = ref(false);
    let selected = ref(null);
    let fbData = reactive({
      data: [],
      paging: null,
    });

    onMounted(async () => {
      try {
        isLoading.value = true;
        fbData.data = await store.dispatch("social/getFacebookPages", {
          filter_existing_pages: true,
        });
      } catch (error) {
        console.error("Error: facebook fetch failed" + error);
      } finally {
        isLoading.value = false;
      }
    });

    const onSelect = (social) => {
      selected.value = social;
      emit("list-select", social);
    };
    return {
      fbData,
      onSelect,
      selected,
      isLoading,
    };
  },
};
</script>
<style lang="postcss" scoped>
.fb-list {
  list-style: none;
  padding: 1em;
}
</style>
