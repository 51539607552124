<template>
  <div class="instagram-card">
    <h2>Instagram Card #{{ editor.socialId }}</h2>
    <form @submit.prevent="saveData">
      <div class="form-group">
        <label for="instagramLink">Instagram Link</label>
        <input
          type="text"
          id="instagramLink"
          v-model="localEditor.instagram_Link"
          required
        />
      </div>
      <div class="form-group">
        <label for="fanbase">Fanbase</label>
        <input
          type="number"
          id="fanbase"
          v-model="localEditor.instagram_Fanbase"
          required
        />
      </div>
      <button type="submit" :disabled="!isFormValid">Save</button>
    </form>
  </div>
</template>

<script>
import { ApiEndPoints } from "@/api/Endpoints";
import axios from "axios";

export default {
  props: {
    editor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localEditor: {
        Instagram_Link: "",
        Instagram_Fanbase: 0,
      },
    };
  },
  created() {
    this.initializeLocalEditor();
  },
  computed: {
    isFormValid() {
      return (
        this.localEditor.instagram_Link &&
        this.localEditor.instagram_Fanbase &&
        (this.localEditor.instagram_Link !== this.editor.instagram_Link ||
          this.localEditor.instagram_Fanbase !== this.editor.instagram_Fanbase)
      );
    },
  },
  methods: {
    initializeLocalEditor() {
      this.localEditor = { ...this.editor };
    },
    async saveData() {
      if (this.editor?.socialId == null) return;
      try {
        await axios.put(
          ApiEndPoints.SOCIAL + `/${this.editor.socialId}/temp-ig-data`,
          {
            InstagramLink: this.localEditor.instagram_Link,
            InstagramFanbase: this.localEditor.instagram_Fanbase,
          }
        );
        alert("Instagram data saved successfully!");
        this.$emit("data-saved", this.localEditor);
      } catch (error) {
        console.error("Error saving Instagram data:", error);
      }
    },
  },
};
</script>

<style scoped>
.instagram-card {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

button:hover:enabled {
  background-color: #0056b3;
}
</style>
